import { hasSystemTag, SystemTags } from '@tunasong/models'
import { entitiesApi, useChild } from '@tunasong/redux'
import { type Entity, type Persisted } from '@tunasong/schemas'
import { type FC } from 'react'
import { RevealButton } from '../buttons/reveal-button.js'
import { UploadStoredEntity } from '../media/upload.js'
import manifest from '@tunasong/manifest'

export interface FolderControlsProps {
  entity?: Persisted<Entity>
  onActive?(active: boolean): void
}

export const FolderControls: FC<FolderControlsProps> = props => {
  const { entity: folder, onActive } = props

  const handleActive = (active: boolean) => () => {
    if (onActive) {
      onActive(active)
    }
  }

  const { child: existingHeaderImage } = useChild({
    parentId: folder?.id,
    filter: hasSystemTag(SystemTags.HeaderImage),
    networkFirst: true,
  })
  const [deleteEntity] = entitiesApi.useDeleteEntityMutation()
  const handleUploading = () => {
    if (!existingHeaderImage) {
      return
    }
    deleteEntity({ entity: existingHeaderImage })
  }

  if (!folder) {
    return null
  }

  return (
    <RevealButton tabIndex={-1} name={`Header Image`} onShow={handleActive(true)} onHide={handleActive(false)}>
      <UploadStoredEntity
        accept={manifest.entityTypeMimeExtensions.image}
        parentId={folder.id}
        contentHint="image"
        tags={[SystemTags.HeaderImage]}
        onUploading={handleUploading}
        onUploaded={handleActive(false)}
      />
    </RevealButton>
  )
}
